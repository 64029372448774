// Libraries
import { Box, chakra, Flex } from "@chakra-ui/react";
// Nextjs
import { useRouter } from "next/router";
import React, { useState } from "react";
// Components
import Layout from "../Layout";

interface pageLayoutProps {
  children: any;
  title: string;
  linkTitle: string;
  goTo?: () => void;
}

const AuthPageLayout = ({ children, title, linkTitle, goTo }: pageLayoutProps) => {
  // const pp = title === "login" ? 0 : "7rem";

  const router = useRouter();

  const [activeClass, setActiveClass] = useState("student");

  return (
    <Layout title={title} position="auth">
      <main>
        <Flex bg="blue.500" flexDir={{ base: "column", lg: "row" }} w="100%" px={6} pb={5} minH={{ lg: "calc(100vh - 80px)" }}>
          {/* Column with data */}
          <Flex justifyContent="center" alignItems="center" h="100%" w={{ base: "100%", lg: "60%" }}>
            <Flex w={{ base: "100%", md: "500px" }} justifyContent="center" alignItems="center" flexDir="column">
              {/* Tabs */}
              <Flex
                mx="auto"
                // fontSize="sm"
                fontWeight={700}
                mt={10}
                justifyContent="center"
                color="gray.400"
                borderRadius="lg"
                p={1}
                bg="blue.400">
                {/* Student login */}
                <Box
                  cursor="pointer"
                  onClick={() => setActiveClass("student")}
                  py={2}
                  px={4}
                  bg="blue.300"
                  boxShadow="0 0 3.5px -1px hsla(0,0%,0%,0.35)"
                  borderRadius="6px"
                  color={activeClass === "student" && "white"}>
                  Student
                </Box>

                {/* Admin login*/}
                <Box
                  my="auto"
                  cursor="pointer"
                  onClick={() => {
                    if (typeof window !== "undefined") {
                      let pathname = router.pathname === "/login" ? "" : "create";
                      let pathTogoTo = `${process.env.NEXT_PUBLIC_AUTHOR_PLATFORM}/${pathname}`;

                      window.location.href = pathTogoTo;
                    }
                  }}
                  py={2}
                  px={4}
                  ml={1}
                  transition="all .3s ease"
                  _hover={{ bg: "blue.300", color: "white" }}
                  borderRadius="6px"
                  color={"blue.200"}>
                  Class Admin
                </Box>
              </Flex>

              <Box w="100%" mb={4}>
                {children}
              </Box>

              {/* <Box mt={{ lg: 8 }} mb={{ base: 8, md: 0 }}>
                <FormLink fontSize="md" onClick={goTo} title={linkTitle} />
              </Box> */}
            </Flex>
          </Flex>

          {/* <Text>Hello</Text> */}
          {/* Empty background */}
          <Flex
            mt={{ base: "65px", md: 0 }}
            d={{ base: "none", lg: "flex" }}
            borderRadius="lg"
            bg="blue.400"
            flexDir="column"
            minH="100%"
            w={{ base: "100%", lg: "40%" }}>
            <chakra.svg w="80px" h="auto" viewBox="0 0 512 512" m="auto" color="blue.500">
              <path
                d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28Z"
                fill="currentColor"
                fillRule="evenodd"
              />
              <path
                d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28ZM123.156,118.7,94.247,144.3V337.89l76.239,76.242H365.309l24.04-21.281H226.512a17.355,17.355,0,0,1-12.263-5.08l-84.28-84.28a17.338,17.338,0,0,1-5.076-12.088Z"
                fill="currentColor"
                fillRule="evenodd"
              />
              <path
                d="M140.116,289.117V80.531H389.058L435.1,122.672V375.511H226.516Zm30.348-178.238V276.547l68.622,68.616H404.749V136.037l-27.484-25.158Z"
                fill="currentColor"
                fillRule="evenodd"
              />
              <path d="M279.324,185.949H239.263v88.892H329.75V237.172H279.324Z" fill="currentColor" />
            </chakra.svg>
          </Flex>
        </Flex>
      </main>
    </Layout>
  );
};

export default AuthPageLayout;
