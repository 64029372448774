import { Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton, FlexProps } from "@chakra-ui/react";
import React from "react";

/**
 * Custom Toast
 */
export type ToastProps = {
  status?: "error" | "success" | "warning" | "info";
  variant?: "subtle" | "solid" | "left-accent" | "top-accent";
  title: string;
  description?: string;
  props: any;
};

// TODO: Wrap this up as the toast instead
const Toast = ({ status = "info", variant = "subtle", description, title, props }: ToastProps & FlexProps) => (
  <Alert status={status} variant={variant} id={props.id} borderRadius="6px" boxShadow="md" py={0} px={0}>
    <AlertIcon ml={3} mr={0} />
    <AlertTitle mr={2} p={3}>
      {title}
    </AlertTitle>
    {description && <AlertDescription>{description}</AlertDescription>}
    <CloseButton
      width="unset"
      height="unset"
      ml="auto"
      p={4}
      borderRadius="0 6px 6px 0"
      position="relative"
      borderLeft="1px solid #00000020"
      onClick={props.onClose}
    />
  </Alert>
);
export default Toast;
