import { gql } from "@apollo/client";

const GETSINGLECLASS = gql`
  query getClass($class_code: String!) {
    get_single_class(class_code: $class_code) {
      message
      value
      class {
        _id
        class_name
        class_code
        class_description
        avatar
        is_private
        paid_class
        class_certification

        class_start_date
        class_end_date

        registration_start
        registration_end

        class_requirements
        reason_to_enrol
        class_is_for

        facilitators {
          facilitator {
            full_name
          }
          role
        }

        class_pace
        created_by {
          full_name
          avatar
        }
        students {
          student {
            _id
            full_name
          }
          date_joined
        }

        lessons {
          _id
          lesson_name
          lesson_content
          objectives
          estimated_reading_time
          has_uploaded_video
          video {
            _id
            path
            url
            mime_type
            date_uploaded
          }
        }

        student_waitlist {
          _id
          full_name
        }
      }
    }
  }
`;

const GET_LESSON_ASSESSMENTS = gql`
  query($lessonId: ID!) {
    get_lesson_assesment(lessonId: $lessonId) {
      message
      value
      data {
        _id
        quiz {
          _id
          question
          image
          answer_options {
            first_option
            second_option
            third_option
            correct_option
          }
        }
        essay {
          _id
          question
          image
        }
        external {
          _id
          question
          image
          external_instruction
          link
        }
      }
    }
  }
`;

const GET_LESSON_ASSESSMENTS_ANSWERS = gql`
  query($lessonId: ID!) {
    get_student_assesment(lessonId: $lessonId) {
      data {
        _id
        quiz_score
        quiz_answers {
          _id
          assesment_question
          answer
        }
        essay_score
        essay_answers {
          _id
          answer
          assesment_question
          assesment_question_id
          files
        }
        external_score
        external_answers {
          _id
          has_completed
          assesment_question
        }
      }
    }
  }
`;

const GET_JOINED_CLASSES = gql`
  query {
    get_student_profile {
      full_name
      referral_link_enrollments {
        class
        referral_link
      }
      joined_classes {
        class {
          _id
          class_code
          created_by {
            full_name
            avatar
          }
          students {
            student {
              _id
              full_name
            }
            date_joined
          }
          avatar
          class_name
          class_description
          lessons {
            _id
          }
          no_of_students
          paid_class
          plan
        }
        date_registered
      }

      class_discounts {
        class {
          _id
          class_name
          class_code
        }
        discount_type
        referral_link
        signups_from_referral
        referral_target
        target_achieved
        percentage
      }

      wait_list {
        _id
        class_code
        created_by {
          full_name
          avatar
        }
        students {
          student {
            _id
            full_name
          }
          date_joined
        }
        avatar
        class_name
        class_description
        lessons {
          _id
        }
        no_of_students
        paid_class
        plan
      }
    }
  }
`;

const GET_CLASS_LESSONS = gql`
  query getLessons($class_id: ID!) {
    get_class_lessons(classId: $class_id) {
      classInfo {
        _id
        students {
          student {
            _id
            full_name
          }
          date_joined
        }
        class_name
        class_code
        class_description
        avatar
        is_private
        no_of_students
        created_by {
          full_name
          avatar
          email
        }
        audio {
          _id
          path
          url
          mime_type
          date_uploaded
        }
        videos {
          _id
          path
          url
          mime_type
          date_uploaded
        }
        pdf {
          _id
          path
          url
          mime_type
          date_uploaded
        }
        paid_class
        price
        plan
      }
      edges {
        _id
        lesson_name
        lesson_content
        objectives
        estimated_reading_time
        lesson_locked
        has_uploaded_video
        free_to_view
        video {
          url
          path
          mime_type
          date_uploaded
        }
        no_of_comments
        class {
          students {
            student {
              _id
              full_name
            }
            date_joined
          }
          class_name
          class_code
          class_description
          avatar
          is_private
          no_of_students
          created_by {
            full_name
            avatar
            email
          }
          audio {
            _id
            path
            url
            mime_type
            date_uploaded
          }

          videos {
            _id
            path
            url
            mime_type
            date_uploaded
          }

          pdf {
            _id
            path
            url
            mime_type
            date_uploaded
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_LESSON_COMMENTS = gql`
  query getComments($lesson_id: ID!) {
    get_lesson_comments(lessonId: $lesson_id) {
      edges {
        _id
        message
        no_of_replies
        student {
          _id
          full_name
        }
        author {
          _id
          full_name
        }
        createdAt
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_COMMENT_REPLIES = gql`
  query getReplies($comment_id: ID!) {
    get_comment_replies(commentId: $comment_id) {
      edges {
        _id
        message
        no_of_replies
        comment {
          _id
        }
        student {
          _id
          full_name
        }
        author {
          _id
          full_name
        }
        createdAt
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_STUDENT_MEETINGS = gql`
  query {
    student_meetings {
      edges {
        topic
        password
        class {
          _id
          class_name
        }
        meeting_id
        start_time
        join_url
        meeting_type
      }
    }
  }
`;

export {
  GETSINGLECLASS,
  GET_JOINED_CLASSES,
  GET_CLASS_LESSONS,
  GET_LESSON_COMMENTS,
  GET_COMMENT_REPLIES,
  GET_LESSON_ASSESSMENTS,
  GET_LESSON_ASSESSMENTS_ANSWERS,
  GET_STUDENT_MEETINGS,
};
