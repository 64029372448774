import { gql } from "@apollo/client";

const JOINCLASS = gql`
  mutation($class_code: String!, $referral_link: String) {
    student_join_class(class_code: $class_code, referral_link: $referral_link) {
      message
      value
      student {
        _id
        joined_classes {
          class {
            _id
            class_name
            class_code
          }
        }
      }
    }
  }
`;

const STUDENT_COMPLETE_LESSON = gql`
  mutation($lessonId: ID!) {
    student_completed_lesson(lessonId: $lessonId) {
      message
      value
      action
    }
  }
`;

const ACCEPTINVITATION = gql`
  mutation($classId: ID!) {
    accept_class_invitation(classId: $classId) {
      message
      value
    }
  }
`;

const DECLINEINVITATION = gql`
  mutation($classId: ID!) {
    decline_class_invitation(classId: $classId) {
      message
      value
    }
  }
`;

const TAKE_ESSAY_ASSESSMENT = gql`
  mutation(
    $lessonId: ID!
    $questionId: ID!
    $student_answer: String!
    $files: [Upload]
  ) {
    take_essay_assesment(
      lessonId: $lessonId
      questionId: $questionId
      student_answer: $student_answer
      files: $files
    ) {
      message
      value
    }
  }
`;

const TAKE_EXTERNAL_ASSESSMENT = gql`
  mutation($lessonId: ID!, $questionId: ID!, $has_completed: Boolean) {
    take_external_assesment(
      lessonId: $lessonId
      questionId: $questionId
      has_completed: $has_completed
    ) {
      message
      value
    }
  }
`;

const TAKE_QUIZ_ASSESSMENT = gql`
  mutation($lessonId: ID!, $classId: ID!, $quiz_answers: [StudentQuizAnswers]) {
    take_quiz_assesment(
      lessonId: $lessonId
      classId: $classId
      quiz_answers: $quiz_answers
    ) {
      message
      value
    }
  }
`;

/* const STUDENT_PAY_STACK = gql`
  mutation($classId: ID!, $ref: String!, $referral_link: String) {
    student_pay_stack(
      classId: $classId
      ref: $ref
      referral_link: $referral_link
    ) {
      message
      value
    }
  }
`; */

const STUDENT_PAY_FOR_CLASS = gql`
  mutation(
    $classId: ID!
    $referral_link: String
    $ref: String!
    $gateway: String!
  ) {
    student_pay_for_class(
      classId: $classId
      referral_link: $referral_link
      ref: $ref
      gateway: $gateway
    ) {
      message
      value
      action
    }
  }
`;

/* const STUDENT_FLUTTERWAVE = gql`
  mutation($classId: ID!, $transaction_id: Int!) {
    student_flutterwave_pay(
      classId: $classId
      transaction_id: $transaction_id
    ) {
      message
      value
    }
  }
`;
 8?
/* const STUDENT_PAY_WITH_CARD_STACK = gql`
  mutation(
    $classId: ID!
    $last4: String!
    $card_type: String!
    $amount: Int!
    $author_email: String!
    $author_full_name: String!
    $class_plan: String!
    $referral_link: String
  ) {
    student_pay_with_card_stack(
      classId: $classId
      last4: $last4
      card_type: $card_type
      amount: $amount
      author_email: $author_email
      author_full_name: $author_full_name
      class_plan: $class_plan
      referral_link: $referral_link
    ) {
      message
      value
    }
  }
`; */
const STUDENT_PAY_WITH_CARD = gql`
  mutation(
    $classId: ID!
    $last4: String!
    $card_type: String
    $amount: Int!
    $author_email: String!
    $author_full_name: String!
    $class_plan: String!
    $gateway: String!
    $referral_link: String
  ) {
    student_pay_with_card(
      classId: $classId
      last4: $last4
      card_type: $card_type
      amount: $amount
      author_email: $author_email
      author_full_name: $author_full_name
      class_plan: $class_plan
      gateway: $gateway
      referral_link: $referral_link
    ) {
      message
      value
    }
  }
`;

const STUDENT_PAY_WITH_CARD_FLUTTERWAVE = gql`
  mutation(
    $classId: ID!
    $last4: String!
    $amount_to_be_paid: Int!
    $author_email: String!
    $author_full_name: String!
    $class_plan: String!
  ) {
    student_pay_with_card_flutter_wave(
      classId: $classId
      last4: $last4
      amount_to_be_paid: $amount_to_be_paid
      author_email: $author_email
      author_full_name: $author_full_name
      class_plan: $class_plan
    ) {
      message
      value
      action
    }
  }
`;

export {
  JOINCLASS,
  ACCEPTINVITATION,
  DECLINEINVITATION,
  TAKE_ESSAY_ASSESSMENT,
  TAKE_EXTERNAL_ASSESSMENT,
  TAKE_QUIZ_ASSESSMENT,
  STUDENT_COMPLETE_LESSON,
  STUDENT_PAY_FOR_CLASS,
  STUDENT_PAY_WITH_CARD,
  //STUDENT_PAY_STACK,
  //STUDENT_PAY_WITH_CARD_STACK,
  STUDENT_PAY_WITH_CARD_FLUTTERWAVE,
};
