import Cookie from "js-cookie";
import { useRouter } from 'next/router';
import React from 'react';
// import { PrivateRoute } from '../Helpers/PrivateRoute';
import AuthPageLayout from './../components/Auth/AuthPageLayout';
import LoginComponent from './../components/Auth/Login';

const Login = () => {
  function isBrowser() {
    return typeof window !== "undefined";
  }

  const AuthToken = Cookie.get("x-auth-token");
  const router = useRouter();

  if (isBrowser() && !!AuthToken) {
    router.push("/classes/enrolled");
    return <></>;
  }

  if (typeof window !== "undefined") {
    return (
      <AuthPageLayout
        title="Login"
        linkTitle="I don't have an account"
        goTo={() => router.push("/signup")}
      >
        <LoginComponent
          justLogin={true}
          joinAClass={false}
          goToReset={() => router.push("/reset-password")}
        />
      </AuthPageLayout>
    );
  } else {
    return <></>;
  }
};

export default Login;



/* 
const Login = () => {
	const router = useRouter();

	if(typeof window !== "undefined"){
		return (
			<AuthPageLayout title="Login" linkTitle="I don't have an account" goTo={() => router.push('/signup')}>
				<LoginComponent justLogin={true} joinAClass={false} goToReset={() => router.push('/reset-password')} />
			</AuthPageLayout>
		);
	}else{
		return(
			<></>
		);
	}

	
};

export default AuthRoute(Login);
 */